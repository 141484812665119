export const currencyFormatter = (value: number) => {
	let formattedText: string;
	if (value < 1000) formattedText = value.toFixed(1) + "₺";
	else if (value < 1000000) {
		formattedText = (value / 1000).toFixed(1) + "K ₺";
	} else if (value < 1000000000) {
		formattedText = (value / 1000000).toFixed(1) + "M ₺";
	} else {
		formattedText = (value / 1000000000).toFixed(1) + "B ₺";
	}
	return formattedText.replace(".0", "");
};
