import { Box, Stack, useTheme } from "@mui/material";
import React from "react";
import { BranchDAO, DocumentDAO, FaqsDAO, NewsDAO } from "../../../util/generators/types";
import { useNavigateToElement } from "../../../util/hooks/useNavigateToElement.hook";
import { searchDropdownWidth } from "../../containers";
import { ItemFilter } from "../../containers/types";
import { LocalizedGenericBody, Popover } from "../../elements";
import { SearchBar } from "../bars";
import {
	SearchDocumentFilterIcon,
	SearchFaqFilterIcon,
	SearchNewsFilterIcon,
	SearchBranchFilterIcon,
	SearchNoneFilterIcon
} from "../images";
import { SearchTabItem } from "../items";
import {
	DocumentsSearchList,
	FaqsSearchList,
	NewsSearchList,
	BranchSearchList
} from "../lists";
import { SearchDropdownEmptyContent } from "../contents";

interface Props {
	handleChange: (
		event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
	) => void;
	handleItemFilterChange: (itemFilter: ItemFilter) => void;
	setClose: () => void;
	anchorElement: undefined | null;
	openCondition: boolean;
	filter: string;
	filteredBranches: BranchDAO[];
	filteredFaqs: FaqsDAO[];
	filteredDocuments: DocumentDAO[];
	filteredNews: NewsDAO[];
	selectedFilter: ItemFilter;
	width: string | Record<string, string>;
	marginTop: number;
}

interface TabItem {
	filter: ItemFilter;
	IconComponent: React.FC<{ isSelected: boolean }>;
}

export const SearchDropdown: React.FC<Props> = ({
	anchorElement,
	handleChange,
	handleItemFilterChange,
	setClose,
	openCondition,
	filter,
	filteredFaqs,
	filteredDocuments,
	filteredNews,
	filteredBranches,
	selectedFilter,
	width,
	marginTop
}) => {
	const tabItems: TabItem[] = [
		{ filter: "none", IconComponent: SearchNoneFilterIcon },
		{ filter: "faq", IconComponent: SearchFaqFilterIcon },
		{ filter: "document", IconComponent: SearchDocumentFilterIcon },
		{ filter: "news", IconComponent: SearchNewsFilterIcon },
		{ filter: "branch", IconComponent: SearchBranchFilterIcon }
	];
	const theme = useTheme();
	const navigate = useNavigateToElement();

	const headerPadding = "2rem";
	const popoverTransitionDuration = 0.75 * 1000;

	const handleInquiryClick = () => {
		setClose();
		navigate("/", "home-general-inquiry");
	};

	const displaySearchItems = () => {
		const itemCount = selectedFilter === "none" ? 5 : 10;

		const faqs = { filterType: "faq", list: filteredFaqs, Component: FaqsSearchList };
		const documents = {
			filterType: "document",
			list: filteredDocuments,
			Component: DocumentsSearchList
		};
		const news = { filterType: "news", list: filteredNews, Component: NewsSearchList };
		const branches = {
			filterType: "branch",
			list: filteredBranches,
			Component: BranchSearchList
		};

		const items: {
			filterType: string;
			list: FaqsDAO[] | DocumentDAO[] | NewsDAO[] | BranchDAO[];
			Component: any;
		}[] = [faqs, documents, news, branches];

		return items.map(({ filterType, list, Component }, index) => {
			if (!list.length) return null;
			if (["none", filterType].indexOf(selectedFilter) === -1) return null;
			return (
				<Component key={index} items={list.slice(0, itemCount)} setClose={setClose} />
			);
		});
	};

	const isFiltered =
		!!filter &&
		[filteredFaqs, filteredDocuments, filteredNews, filteredBranches].some(
			list => list?.length > 0
		);

	return (
		<Popover
			anchorEl={anchorElement}
			anchorOrigin={{
				vertical: "top",
				horizontal: "left"
			}}
			onClose={setClose}
			open={openCondition}
			transitionDuration={{
				enter: 0,
				exit: popoverTransitionDuration,
				appear: popoverTransitionDuration
			}}
			TransitionProps={{
				enter: false
			}}
			BackdropProps={{
				transitionDuration: popoverTransitionDuration,
				sx: {
					background: `${theme.palette.common.black}75`
				}
			}}
			PaperProps={{
				sx: {
					background: "transparent",
					boxShadow: "none",
					top: {
						xs: "7.3125rem !important",
						md: "2.8125rem !important",
						xl: "3.4375rem !important"
					}
				}
			}}
		>
			<Box
				sx={{
					width: searchDropdownWidth,
					minHeight: "2.56rem",
					overflow: "hidden",
					paddingRight: { xs: headerPadding, md: "0" }
				}}
			>
				<Box
					sx={{
						width,
						transition: "width 0.5s",
						paddingRight: { xs: headerPadding, md: "0" }
					}}
				>
					<SearchBar setClose={setClose} handleChange={handleChange} filter={filter} />
				</Box>
				<Box
					sx={{
						overflow: "hidden",
						borderRadius: "1rem",
						marginTop: "0.75rem"
					}}
				>
					<Box
						sx={{
							background: theme.palette.common.white,
							borderRadius: "1rem",
							marginTop: `${marginTop - 12}px`,
							transition: "all 0.75s ease-in-out"
						}}
					>
						{isFiltered ? (
							<Stack
								direction="row"
								alignItems="center"
								justifyContent="center"
								spacing="0.75rem"
								paddingTop="1rem"
								sx={{ borderBottom: `1px solid ${theme.palette.grey[300]}` }}
							>
								{tabItems.map(({ filter, IconComponent }) => (
									<SearchTabItem
										key={filter}
										handleClick={handleItemFilterChange}
										IconComponent={IconComponent}
										itemFilter={filter}
										selectedFilter={selectedFilter}
									/>
								))}
							</Stack>
						) : null}
						<Box
							sx={{
								overflow: "auto",
								transition: "all",
								transitionDuration: "0.75s",
								maxHeight: { xs: "50vh", md: "60vh" }
							}}
						>
							{isFiltered ? (
								displaySearchItems()
							) : (
								<SearchDropdownEmptyContent filter={filter} />
							)}
						</Box>
						<Box sx={{ padding: "0.5rem" }}>
							<Stack
								direction={{ xs: "column", md: "row" }}
								justifyContent="center"
								alignItems="center"
								spacing={{ xs: "0", md: "0.2rem" }}
							>
								<LocalizedGenericBody
									variants={{
										mobile: "body4-primary-medium",
										desktop: "body3-primary-medium"
									}}
									localizationKey="search_results_helper_text"
									sx={{
										textAlign: "center",
										opacity: 0.6
									}}
								/>
								<Box onClick={handleInquiryClick}>
									<LocalizedGenericBody
										variants={{
											mobile: "body4-primary-medium",
											desktop: "body3-primary-medium"
										}}
										localizationKey="search_results_helper_link"
										sx={{
											textDecoration: "underline",
											cursor: "pointer",
											fontWeight: 700,
											opacity: 0.6,
											"&:hover": {
												color: theme.palette.tertiary.main
											}
										}}
									/>
								</Box>
							</Stack>
						</Box>
					</Box>
				</Box>
			</Box>
		</Popover>
	);
};
