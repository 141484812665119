import { GenericImageContainer, LocalizedGenericBody } from "../../elements";
import { useSidebarContext } from "../../../util/contexts/sidebar.context";
import { useResponsive } from "../../../util/hooks/useResponsive.hook";
import { ButtonProps } from "@mui/material";
import { UserMenuButton } from "../../../util/theme/styled-components";

interface Props
	extends Omit<
		ButtonProps,
		"startIcon" | "sx" | "variant" | "noStroke" | "onClick" | "disableRipple"
	> {
	handleClick: () => void;
	localizationKey: string;
	imageSrc: string;
}

export const DashboardSidebarUserMenuItem: React.FC<Props> = ({
	handleClick,
	imageSrc,
	localizationKey,
	...props
}) => {
	const { isLarge } = useResponsive();
	const { isOpen, setIsOpen } = useSidebarContext();

	const handleItemClick = () => {
		handleClick();
		if (isLarge && isOpen) setIsOpen(false);
	};

	return (
		<UserMenuButton
			{...props}
			disableRipple
			onClick={handleItemClick}
			startIcon={
				<GenericImageContainer
					imageAlt={`dashboard-${localizationKey}-icon`}
					imageSrc={imageSrc}
					width="1.25rem"
					style={{ padding: "0.125rem" }}
				/>
			}
			$isOpen={isOpen}
		>
			{isOpen ? (
				<LocalizedGenericBody
					localizationKey={localizationKey}
					variant="body2-primary-medium"
					sx={{ whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis" }}
				/>
			) : null}
		</UserMenuButton>
	);
};
