import { Box, Divider, Stack } from "@mui/material";
import {
	FastEditCollapse,
	FastEditCollapseButton,
	FastEditReturnBox
} from "../../../util/theme/styled-components";
import { FastEditReturnButton, PublishButton } from "../buttons";
import React from "react";
import { FastEditCollapseButtonImage } from "../images";

interface Props {
	handleDialogToggle: () => void;
	handleDrawerToggle: () => void;
	drawerOpen: boolean;
	isPublished: boolean;
	lastPublishDate: Date;
}

export const FastEditSidebarView: React.FC<Props> = ({
	handleDialogToggle,
	handleDrawerToggle,
	drawerOpen,
	isPublished,
	lastPublishDate
}) => (
	<Stack
		data-cy="fast-edit-sidebar"
		direction="row"
		sx={{ position: "fixed", bottom: "0rem", left: "0rem", zIndex: 2 }}
	>
		<FastEditCollapse
			data-cy="fast-edit-collapse"
			in={drawerOpen}
			orientation="horizontal"
		>
			<FastEditReturnBox>
				<Box p="0.5rem">
					<PublishButton
						handleClick={handleDialogToggle}
						isPublished={isPublished}
						lastPublishDate={lastPublishDate}
					/>
				</Box>
				<Divider variant="fullwidth-primary" sx={{ opacity: "0.13" }} />
				<Box p="0.5rem">
					<FastEditReturnButton />
				</Box>
			</FastEditReturnBox>
		</FastEditCollapse>
		<FastEditCollapseButton onClick={handleDrawerToggle}>
			<FastEditCollapseButtonImage open={drawerOpen} />
		</FastEditCollapseButton>
	</Stack>
);
