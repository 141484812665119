import React from "react";

export const useScrollLock = (isLocked: boolean) => {
	React.useEffect(() => {
		const initialScrollPosition = window.scrollY;
		const preservePosition = () => window.scrollTo(0, initialScrollPosition);
		const preventScroll = (event: WheelEvent | TouchEvent) => event.preventDefault();

		if (isLocked) {
			window.addEventListener("wheel", preventScroll, { passive: false });
			window.addEventListener("touchmove", preventScroll, { passive: false });
			window.addEventListener("scroll", preservePosition);
		} else {
			window.removeEventListener("wheel", preventScroll);
			window.removeEventListener("touchmove", preventScroll);
			window.removeEventListener("scroll", preservePosition);
		}

		return () => {
			window.removeEventListener("wheel", preventScroll);
			window.removeEventListener("touchmove", preventScroll);
			window.removeEventListener("scroll", preservePosition);
		};
	}, [isLocked]);
};
