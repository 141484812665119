import { AxiosError } from "axios";

export const adminHandler = (error: AxiosError<any>) => {
	if (!error.response) throw error;

	switch (error.response.status) {
		case 401:
			localStorage.removeItem("token");
			throw error;
		case 400:
			if (error.response.data.error) throw new Error(error.response.data.error);
			throw error;
		default:
			throw error;
	}
};
