import { PieChartLegendItem } from "../items";
import { Stack } from "@mui/material";
import { useChartContext } from "../../../util/contexts/chart.context";

export const PieChartLegendList: React.FC = () => {
	const { chartItems, highlightedIndex } = useChartContext();

	return (
		<Stack
			direction="column"
			spacing="0.38rem"
			width="100%"
			maxWidth={{ xs: "19.4rem", md: "16.9rem" }}
			minWidth="9.375rem"
		>
			{chartItems.map((chartItem, index) => (
				<PieChartLegendItem
					key={index}
					chartItem={chartItem}
					index={index}
					highlighted={highlightedIndex === index}
				/>
			))}
		</Stack>
	);
};
