import {
	styled,
	TextareaAutosize,
	InputBase,
	TextField,
	Checkbox,
	Select,
	InputLabel,
	Input,
	MenuItem
} from "@mui/material";
import { transientOptions } from "../options";
import { ColorDictionary } from "../../dictionaries/color.dictionary";
import { SubmissionStatus, SubmissionStatusEnum } from "../../../views/containers/types";

export const ContactFormTextField = styled(TextField)(({ theme }) => ({
	".MuiOutlinedInput-root": {
		background: theme.palette.common.white,
		border: `1px solid ${theme.palette.common.white}`,
		borderRadius: "0.375rem",
		color: theme.palette.primary.main,

		display: "flex",
		alignItems: "center",
		padding: 0,
		gap: "0.500rem",
		height: "3.00rem",

		fontSize: theme.typography.body3.fontSize,
		fontWeight: "500",

		"&.Mui-error": {
			border: `1px solid ${theme.palette.error.main}`
		},

		"& > .MuiOutlinedInput-notchedOutline": {
			border: "none"
		}
	},
	".MuiFormHelperText-root": {
		fontSize: theme.typography.body3.fontSize,
		fontWeight: 500,
		color: theme.palette.error.dark
	}
}));

export const ContactFormMessageField = styled(TextField)(({ theme }) => ({
	".MuiOutlinedInput-root": {
		background: theme.palette.common.white,
		border: `1px solid ${theme.palette.common.white}`,
		borderRadius: "0.375rem",
		color: theme.palette.primary.main,

		display: "flex",
		alignItems: "flex-start",
		padding: "1.00rem",
		gap: "0.500rem",
		height: "7.44rem",

		overflowY: "auto",
		"::-webkit-scrollbar": {
			display: "none"
		},

		fontSize: theme.typography.body3.fontSize,
		fontWeight: 500,

		"&.Mui-error": {
			border: `1px solid ${theme.palette.error.main}`
		},

		"& > .MuiOutlinedInput-notchedOutline": {
			border: "none"
		}
	},
	".MuiFormHelperText-root": {
		fontSize: theme.typography.body3.fontSize,
		fontWeight: 500,
		color: theme.palette.error.dark
	}
}));

export const AdminPanelTextarea = styled(TextareaAutosize)(({ theme }) => {
	return {
		width: "100%",
		border: "none",
		background: theme.palette.grey[300],
		borderRadius: "0.25rem",
		padding: "0.5rem",
		"::placeholder": {
			fontFamily: "Raleway",
			...theme.typography.body3
		}
	};
});

export const ApplicationFormTextAreaInput = styled(AdminPanelTextarea)(({ theme }) => ({
	borderRadius: "0.500rem",
	background: theme.palette.grey[300],
	border: "none",
	padding: "1.00rem",
	outline: "none"
}));

export const SearchBarInput = styled(InputBase)(({ theme }) => ({
	border: `1px solid ${theme.palette.grey[300]}`,
	borderRadius: "1.81rem",
	padding: "0.125rem 1rem",
	minHeight: "2.5rem",
	background: theme.palette.common.white,
	fontFamily: "Raleway",
	fontWeight: "500",
	color: theme.palette.primary.main,
	"&:placeholder": {
		opacity: 0.5
	}
}));

export const HeaderSearchInput = styled(
	SearchBarInput,
	transientOptions
)<{ $isopen: boolean }>(({ $isopen, theme }) => ({
	border: $isopen ? "unset" : `1px solid ${theme.palette.primary.main}66`,
	background: `${theme.palette.secondary.main}0d`
}));

export const ApplicationFormTextFieldInput = styled(TextField)(({ theme }) => ({
	".MuiInputBase-root": {
		height: "3.00rem",
		background: theme.palette.grey[300],
		borderRadius: "0.500rem",
		padding: "1.00rem 0.813rem"
	}
}));

export const ApplicationFormCheckbox = styled(Checkbox)(({ theme }) => ({
	".MuiSvgIcon-root": {
		color: theme.palette.primary.main,
		fontSize: 21
	}
}));

export const LoginTextField = styled(
	TextField,
	transientOptions
)<{ $submissionStatus: SubmissionStatus }>(({ $submissionStatus, theme }) => ({
	width: "100%",
	borderRadius: "0.5rem",

	outline:
		$submissionStatus === SubmissionStatusEnum.error
			? `1px solid ${theme.palette.error.main}`
			: "none",

	".Mui-focused": {
		outline: `1px solid ${theme.palette.primary.main}`
	},

	".MuiInputBase-root": {
		borderRadius: "0.5rem",
		height: "3rem",
		background: theme.palette.grey[300],
		padding: 0,

		fontFamily: "Raleway",
		fontWeight: "500",
		fontSize: "1rem",
		color: theme.palette.primary.main,

		[theme.breakpoints.down("md")]: {
			fontSize: "0.875rem",
			height: "2.75rem"
		}
	},
	".MuiFormLabel-root": {
		...theme.typography.body3,
		fontWeight: 500,
		opacity: 0.6,
		padding: 0,
		lineHeight: "100%",
		color: theme.palette.primary.main,
		outline: "none"
	},
	".MuiInputBase-input": {
		boxSizing: "border-box",
		height: "100%",
		borderRadius: "0.5rem",
		width: "100%"
	}
}));

export const FilterMenuItem = styled(MenuItem)(({ theme }) => ({
	display: "flex",
	height: "2.25rem",
	padding: "0.5rem 0.75rem",
	justifyContent: "center",
	alignItems: "center",
	gap: "0.375rem",
	"&:not(:last-child)": {
		borderBottom: `1px solid ${theme.palette.grey[300]}`
	}
}));

export const FilterInputLabel = styled(InputLabel)(() => ({
	position: "unset",
	transform: "translate(0, calc(50% + 1rem))",
	paddingRight: "2.5rem",
	minWidth: "100%",
	"&.MuiInputLabel-root": {
		top: "-0.6rem",
		overflow: "hidden"
	},
	"&.MuiInputLabel-shrink": { display: "none" }
}));

export const AdminSearchField = styled(Input)(({ theme }) => ({
	height: "2rem",
	background: `${ColorDictionary["#60528f"]}0d`,
	borderRadius: "20rem",
	border: `1px solid ${theme.palette.primary.main}66`,
	minWidth: "100%",
	padding: "0.5rem",
	fontSize: theme.typography.body3.fontSize,
	fontWeight: 500,
	"&.Mui-focused": {
		background: theme.palette.common.white
	}
}));

export const AdminPanelTextField = styled(TextField)(({ theme }) => ({
	".MuiInputBase-root": {
		background: theme.palette.grey[300],
		borderRadius: "0.5rem"
	},
	".MuiFormLabel-root": {
		...theme.typography.body3,
		fontWeight: 500,
		color: theme.palette.primary.main
	}
}));

export const UserRolesSelectMenuItem = styled(MenuItem)(({ theme }) => ({
	height: "2.75rem",
	background: theme.palette.grey[300],
	"&:not(:last-child)": {
		borderBottom: `1px solid ${theme.palette.grey[300]}`
	}
}));

export const DashboardFooterSelect = styled(Select)(({ theme }) => ({
	height: "100%",
	padding: 0,
	minWidth: "4rem",
	borderRadius: "0.5rem",
	border: `1px solid ${theme.palette.grey[300]}`,
	background: theme.palette.common.white,
	".MuiSelect-select": {
		display: "flex",
		alignItems: "center"
	}
}));

export const DateRangeStyledInput = styled(TextField)(({ theme }) => ({
	"& .MuiInputBase-root": {
		height: "2rem",
		borderRadius: "1rem",
		paddingRight: "0.5rem",
		cursor: "pointer"
	},
	"& .MuiInputBase-input": {
		caretColor: "transparent",
		padding: "0 1rem",
		cursor: "pointer",
		lineHeight: "140%",
		fontWeight: 500,
		fontSize: "0.875rem",
		color: theme.palette.primary.main,
		display: "flex",
		alignItems: "center",
		maxWidth: "9rem",
		overflow: "hidden",
		textOverflow: "ellipsis"
	},
	"& .MuiOutlinedInput-root": {
		"& fieldset": {
			border: `1px solid ${theme.palette.grey[300]}`
		},
		"&:hover fieldset": {
			border: `1px solid ${theme.palette.grey[300]}`,
			boxShadow: `0 0.25rem 0.8125rem 0 ${theme.palette.common.black}12`
		},
		"&.Mui-focused fieldset": {
			border: `1px solid ${theme.palette.grey[300]}`
		}
	},
	input: {
		boxSizing: "border-box"
	},

	width: "100%",
	boxShadow: "none",
	cursor: "pointer"
}));
