import { Stack } from "@mui/material";
import { LocalizedGenericBody } from "../../elements";
import { BackToLoginButton } from "../buttons";

interface Props {
	handleClick: () => void;
}

export const ClientLoginLockedContent: React.FC<Props> = ({ handleClick }) => (
	<Stack px="1rem" spacing={{ xs: "1.5rem", md: "2rem" }} alignItems="center">
		<BackToLoginButton handleClick={handleClick} />
		<LocalizedGenericBody
			localizationKey="client_login_locked_text"
			variant="body3-primary-medium"
			sx={{ textAlign: "center", a: { fontWeight: "bold" } }}
		/>
	</Stack>
);
