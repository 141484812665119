import { Box, InputLabel, Stack } from "@mui/material";
import React from "react";
import {
	ApplicationFormCheckbox,
	ApplicationFormTextAreaInput,
	ApplicationFormTextFieldInput
} from "../../../util/theme/styled-components";
import { LocalizationContainer } from "../../containers";
import { GenericButton, LocalizedGenericBody } from "../../elements";
import { AddIcon } from "../images";
import { ValidatedTextInput } from "./validated-text.input";
import { InputValidation } from "../../../util/validations/input.validation";
import { ApplicationFormInfoFields, TermsAgreementFields } from "../../containers/types";

interface TextAreaProps {
	handleChange: (
		infoField: ApplicationFormInfoFields,
		event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
	) => void;
	infoField: ApplicationFormInfoFields;
	localizationKey: string;
	value: string;
}

interface TextFieldProps extends TextAreaProps {
	setIsValidForField: (
		infoField: ApplicationFormInfoFields,
		isValid: null | boolean
	) => void;
	Validation: typeof InputValidation;
	isValid: null | boolean;
}

export const ApplicationFormTextField: React.FC<TextFieldProps> = ({
	handleChange,
	setIsValidForField,
	infoField,
	isValid,
	localizationKey,
	Validation,
	value
}) => (
	<>
		<InputLabel>
			<LocalizedGenericBody
				variant="body3-dark-grey-medium"
				localizationKey={localizationKey}
			/>
		</InputLabel>
		<ValidatedTextInput
			InputComponent={ApplicationFormTextFieldInput}
			InputProps={{
				variant: "standard",
				autoFocus: false,
				autoComplete: "false",
				fullWidth: true,
				onChange: (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) =>
					handleChange(infoField, event),
				value,
				InputProps: { disableUnderline: true }
			}}
			Validation={Validation}
			isValid={isValid}
			passValidationResult={isValid => setIsValidForField(infoField, isValid)}
		/>
	</>
);

export const ApplicationFormTextArea: React.FC<TextAreaProps> = ({
	handleChange,
	infoField,
	localizationKey,
	value
}) => (
	<>
		<InputLabel>
			<LocalizedGenericBody
				variant="body3-dark-grey-medium"
				localizationKey={localizationKey}
			/>
		</InputLabel>
		<ApplicationFormTextAreaInput
			autoComplete="false"
			onChange={(event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) =>
				handleChange(infoField, event)
			}
			value={value}
			minRows={4}
		/>
	</>
);

interface ButtonProps {
	handleChange: (
		infoField: "invoices" | "checks",
		event: React.ChangeEvent<HTMLInputElement>
	) => void;
	infoField: "invoices" | "checks";
	labelLocalizationKey: string;
	placeholderLocalizationKey: string;
}

export const ApplicationFormFileInput: React.FC<ButtonProps> = ({
	handleChange,
	infoField,
	labelLocalizationKey,
	placeholderLocalizationKey
}) => (
	<>
		<InputLabel>
			<LocalizedGenericBody
				variant="body3-dark-grey-medium"
				localizationKey={labelLocalizationKey}
			/>
		</InputLabel>
		<LocalizationContainer localizationKey={placeholderLocalizationKey}>
			{({ localizedText }) => (
				<Box>
					<GenericButton
						variant="secondary"
						component="label"
						sx={{ width: "100%" }}
						startIcon={<AddIcon />}
						handleClick={() => {
							return;
						}}
					>
						{localizedText}
						<input
							hidden
							multiple
							type="file"
							accept="image/*, .pdf"
							onChange={event => handleChange(infoField, event)}
						/>
					</GenericButton>
				</Box>
			)}
		</LocalizationContainer>
	</>
);

interface CheckboxProps {
	handleChange: (
		infoField: TermsAgreementFields,
		event: React.ChangeEvent<HTMLInputElement>
	) => void;
	onAnchorClick?: () => void;
	checked: boolean;
	id?: string;
	infoField: TermsAgreementFields;
	localizationKey: string;
}

export const ApplicationFormCheckboxInput: React.FC<CheckboxProps> = ({
	handleChange,
	checked,
	infoField,
	id,
	localizationKey,
	onAnchorClick
}) => (
	<Stack id={id} direction="row" alignItems="center" spacing="0.75rem">
		<ApplicationFormCheckbox
			checked={checked}
			onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
				handleChange(infoField, event)
			}
			sx={{ padding: 0 }}
		/>
		<Box onClick={onAnchorClick}>
			<LocalizedGenericBody localizationKey={localizationKey} variant="body3-primary" />
		</Box>
	</Stack>
);
