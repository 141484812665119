import { Box, Typography } from "@mui/material";
import { PageDAO } from "../../../util/generators/types";
import { useLocalizedNavigate } from "../../../util/hooks/useLocalizedNavigate";
import { SectionDropdownItem } from "../../../util/theme/styled-components";
import { NavLink } from "react-router-dom";
import { Popover } from "../../elements";

interface Props {
	closeMenu: () => void;
	anchorEl: HTMLElement | null;
	pagesInHoveredSection: PageDAO[];
}

export const HeaderSectionPopover: React.FC<Props> = ({
	closeMenu,
	anchorEl,
	pagesInHoveredSection
}) => {
	const { prefix } = useLocalizedNavigate();

	return (
		<Popover
			anchorEl={anchorEl}
			open={!!anchorEl}
			onClose={closeMenu}
			sx={{
				".MuiList-root": { padding: 0 },
				".MuiPaper-root": { borderRadius: "0.500rem" },
				pointerEvents: "none"
			}}
			anchorOrigin={{ horizontal: "left", vertical: 30 }}
			disableRestoreFocus
		>
			<Box
				onMouseLeave={closeMenu}
				sx={{ pointerEvents: "auto", borderRadius: "0.500rem" }}
			>
				{pagesInHoveredSection.map((page, index) => (
					<NavLink
						key={index}
						to={`${prefix()}/${page.route}`}
						style={{ textDecoration: "unset" }}
					>
						<SectionDropdownItem onClick={() => closeMenu()}>
							<Typography variant="body4-primary-semi-bold">{page.name}</Typography>
						</SectionDropdownItem>
					</NavLink>
				))}
			</Box>
		</Popover>
	);
};
