import React from "react";
import { useTableContext } from "../../util/contexts/table.context";
import { AdminActivitiesDialog, AdminHomepageTable } from "../components";
import { useRefreshContext } from "../../util/contexts/refresh.context";
import { useUpdateEffect } from "../../util/hooks/useUpdateEffect.hook";

export const AdminHomepageTableContainer: React.FC = () => {
	const { refreshTable } = useTableContext();
	const { crudRefreshTrigger } = useRefreshContext();

	const [dialogOpen, setDialogOpen] = React.useState(false);

	const closeDialog = () => setDialogOpen(false);
	const openDialog = () => setDialogOpen(true);

	useUpdateEffect(() => {
		refreshTable();
	}, [crudRefreshTrigger]);

	return (
		<>
			<AdminHomepageTable openDialog={openDialog} />
			<AdminActivitiesDialog handleDialogClose={closeDialog} dialogOpen={dialogOpen} />
		</>
	);
};
