import { containerHeight, containerMargin } from "../charts/bar.chart";
import { BarItemProps } from "../types";

export const BarChartEmptyBarItem: React.FC<BarItemProps> = ({ dataIndex, x, width }) => {
	const emptyBarHeight = 5;

	const y =
		containerHeight - containerMargin.bottom - containerMargin.top + emptyBarHeight;

	const clipPathId = `clip-${dataIndex}`;

	return (
		<svg>
			<defs>
				<clipPath id={clipPathId}>
					<path
						d={`M${x},${y} h${width - 4} a4,4 0 0 1 4,4 v${
							emptyBarHeight - 4
						} h-${width} v-${emptyBarHeight - 4} a4,4 0 0 1 4,-4 z`}
					/>
				</clipPath>
			</defs>

			<rect
				x={x}
				y={y}
				width={width}
				height={emptyBarHeight}
				fill={"url(#greyGradient)"}
				clipPath={`url(#${clipPathId})`}
			/>
		</svg>
	);
};
