import React from "react";
import {
	AdminHomepageCard,
	ConfirmationDialog,
	FormSnackbar,
	SyncClientsTooltip
} from "../components";
import { AdminActivitiesDAO } from "../../util/generators/types";
import { AdminActivitiesGenerator } from "../../util/generators/private.generator";
import { ActivitiesAdminCommunicator } from "../../communicators/lider.http.communicator";
import { useGeneratorEffect } from "../../util/hooks/useGeneratorEffect.hook";
import { useAuthFormStatus } from "../../util/hooks/useAuthFormStatus.hook";
import { SubmissionStatus, SubmissionStatusEnum } from "./types";
import { useSocketEvent } from "../../util/hooks/useSocketEvent.hook";
import { useRefreshContext } from "../../util/contexts/refresh.context";

import jobOpeningsIcon from "../../assets/images/dashboard/dashboard-sidebar-jobOpenings-icon.svg";
import syncClientsIcon from "../../assets/images/dashboard/admin-homepage-sync-clients-button-icon.svg";
import syncedClientsIcon from "../../assets/images/dashboard/admin-homepage-sync-clients-button-synced-icon.svg";

export const AdminHomepageClientsCardContainer: React.FC = () => {
	const {
		setSubmissionStatus,
		resetSubmissionStatus,
		setSubmissionError,
		submissionStatus
	} = useAuthFormStatus();
	const { triggerCRUDRefresh } = useRefreshContext();

	const [dialogOpen, setDialogOpen] = React.useState(false);
	const [entityCount, setEntityCount] = React.useState(0);
	const [lastActivity, setLastActivity] = React.useState<AdminActivitiesDAO | null>(null);
	const [synced, setSynced] = React.useState(false);
	const [syncInProgress, setSyncInProgress] = React.useState(false);

	const adminActivitiesCommunicator = new ActivitiesAdminCommunicator();
	const adminActivitiesGenerator = new AdminActivitiesGenerator();

	const openDialog = () => setDialogOpen(true);
	const closeDialog = () => setDialogOpen(false);

	const closeSnackbar = () => {
		const isSuccess = submissionStatus === SubmissionStatusEnum.success;
		resetSubmissionStatus();
		if (isSuccess) triggerCRUDRefresh();
	};

	const setClientSyncEvent = ({
		synced,
		syncStatus
	}: {
		synced: boolean;
		syncStatus: SubmissionStatus;
	}) => {
		setSynced(synced);
		setSubmissionStatus(syncStatus);
		setSyncInProgress(syncStatus === SubmissionStatusEnum.inProgress);
	};

	const syncClients = () =>
		adminActivitiesCommunicator
			.syncClients()
			.catch(setSubmissionError)
			.finally(closeDialog);

	const conditionalButtonProps = synced
		? {
				buttonImageSrc: syncedClientsIcon,
				buttonLocalizationKey: "admin-homepage-clients-card_button_synced_text"
		  }
		: {
				buttonImageSrc: syncClientsIcon,
				buttonLocalizationKey: syncInProgress
					? "admin-homepage-clients-card_button_inProgress_text"
					: "admin-homepage-clients-card_button_sync_text"
		  };

	const contentProps = {
		adminActivity: lastActivity
			? lastActivity
			: ({
					createdAt: new Date(0).toISOString()
			  } as AdminActivitiesDAO),
		entityCount,
		localizationKey: "admin-homepage-clients-card_content_resource_text"
	};

	useSocketEvent("clientSync", setClientSyncEvent);

	useGeneratorEffect(
		{
			effect: adminActivitiesCommunicator.getSyncStatus,
			onSuccess: ({ data: { count, synced, syncStatus } }) => {
				setEntityCount(count);
				setSynced(synced);
				setSyncInProgress(syncStatus === SubmissionStatusEnum.inProgress);
			},
			callback: adminActivitiesCommunicator.abort
		},
		[submissionStatus]
	);

	useGeneratorEffect(
		{
			effect: () => adminActivitiesGenerator.many("?resourceName=clients"),
			onSuccess: response => setLastActivity(response[0] || null),
			callback: adminActivitiesGenerator.abort
		},
		[submissionStatus]
	);

	return (
		<>
			<AdminHomepageCard
				buttonAction={openDialog}
				buttonDisabled={synced || syncInProgress}
				{...conditionalButtonProps}
				buttonVariant="newPrimary"
				contentProps={contentProps}
				endIcon={<SyncClientsTooltip />}
				imageSrc={jobOpeningsIcon}
				localizationKey="admin-homepage-clients-card_header_text"
				route="/users?userRoleSubscriptions.userRoleId=client"
			/>
			<ConfirmationDialog
				handleClose={closeDialog}
				handleConfirmation={syncClients}
				dialogOpen={dialogOpen}
				variant="syncClients"
			/>
			<FormSnackbar
				handleClose={closeSnackbar}
				formVariant="sync-clients"
				submissionStatus={submissionStatus}
				autoHideDuration={5000}
			/>
		</>
	);
};
