import React from "react";
import {
	TableSelectSnackbar,
	CheckReportDialog,
	TableSelectSnackbarSendMailIcon
} from "../components";
import { ChecksClientCommunicator } from "../../communicators/lider.http.communicator";
import { useTableContext } from "../../util/contexts/table.context";
import { useUpdateEffect } from "../../util/hooks/useUpdateEffect.hook";
import { Stack } from "@mui/material";
import { useAuthFormStatus } from "../../util/hooks/useAuthFormStatus.hook";
import { ValidationResult } from "../components/types";
import { useLanguageContext } from "../../util/contexts/language.context";

const initialValidationResult = { isValid: null, errorKey: "" };

export const CheckReportContainer: React.FC = () => {
	const {
		submissionStatus,
		setSubmissionError,
		resetSubmissionStatus,
		setSubmissionSuccess
	} = useAuthFormStatus();
	const { selectedElements } = useTableContext();
	const { language } = useLanguageContext();

	const selectedElementIds = selectedElements.map(({ id }) => id);

	const [dialogOpen, setDialogOpen] = React.useState(false);
	const [validEmails, setValidEmails] = React.useState<string[]>([]);
	const [email, setEmail] = React.useState("");
	const [emailValidationResult, setEmailValidationResult] =
		React.useState<ValidationResult>(initialValidationResult);
	const [isLoading, setIsLoading] = React.useState(false);

	const communicator = new ChecksClientCommunicator();

	const resetDialogStates = () => {
		setEmail("");
		setValidEmails([]);
		setEmailValidationResult(initialValidationResult);
		resetSubmissionStatus();
		setIsLoading(false);
	};

	const handleEmailChange = (
		event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
	) => setEmail(event.target.value);

	const handleEmailRemove = (email: string) => {
		const newValidEmails = validEmails.filter(validEmail => validEmail !== email);
		setValidEmails(newValidEmails);
	};

	const toggleDialogOpen = () => setDialogOpen(previous => !previous);

	const handleSendMail = () => {
		if (emailValidationResult.isValid === false) return;
		setIsLoading(true);
		return communicator
			.sendReport({ ids: selectedElementIds, emails: validEmails, language })
			.then(setSubmissionSuccess)
			.catch(setSubmissionError)
			.finally(() => setIsLoading(false));
	};

	const handleValidEmail = (email: string) => {
		setValidEmails(previous =>
			!previous.includes(email) ? [...previous, email] : previous
		);
		setEmail("");
	};

	const handleEmailValidationResult = ({ isValid, errorKey }: ValidationResult) => {
		if (!isValid)
			return setEmailValidationResult({
				isValid: email.length ? isValid : null,
				errorKey
			});
		handleValidEmail(email);
	};

	useUpdateEffect(() => {
		resetDialogStates();
	}, [dialogOpen]);

	const numberOfSelectedElements = selectedElements.length;

	const containerDisplayed = numberOfSelectedElements > 0;

	const multipleElementsSelected = numberOfSelectedElements > 1;

	const localizationKeyPluralAddition = multipleElementsSelected ? "_plural" : "";

	const buttonProps = [
		{
			handleClick: toggleDialogOpen,
			localizationKey: "select_bar_send_mail_button" + localizationKeyPluralAddition,
			startIcon: <TableSelectSnackbarSendMailIcon />
		}
	];

	const emailBarProps = {
		handleEmailChange,
		handleEmailRemove,
		passValidationResult: handleEmailValidationResult,
		email,
		validationResult: emailValidationResult,
		validEmails
	};

	return containerDisplayed ? (
		<Stack alignItems="center">
			<TableSelectSnackbar
				buttonProps={buttonProps}
				selectedElementCountLocalizationKey="select_bar_selected_element_count"
			/>
			<CheckReportDialog
				closeDialog={toggleDialogOpen}
				sendMail={handleSendMail}
				dialogOpen={dialogOpen}
				sendMailStatus={submissionStatus}
				emailBarProps={emailBarProps}
				isLoading={isLoading}
			/>
		</Stack>
	) : null;
};
