import React from "react";
import { Stack } from "@mui/material";
import {
	ChartStack,
	ClientHomeBallotIcon,
	ClientHomeCalendarIcon,
	ClientHomepageBarChart,
	ClientHomepagePieChart,
	ClientHomepageTable
} from "../components";
import { useClientCheckQueries } from "../../util/hooks/useClientCheckQueries.hook";
import { TableProvider } from "../../util/contexts/table.context";
import { TableEnum } from "../../util/types/dashboard-table.types";
import { PieChartProvider } from "../../util/contexts/pie-chart.context";
import { BarChartProvider } from "../../util/contexts/bar-chart.context";

export const chartSpacing = "1rem";

export const ClientHomepageContainer: React.FC = () => {
	const { barChartRedirectUrl } = useClientCheckQueries();

	const { badgeProps: pieChartBadgeProps } = getChartStackProps(
		ClientHomeBallotIcon,
		"check_status",
		"/checks-inquiry"
	);
	const { badgeProps: barChartBadgeProps } = getChartStackProps(
		ClientHomeCalendarIcon,
		"remaining_maturity",
		barChartRedirectUrl
	);

	return (
		<Stack spacing="1rem">
			<Stack direction={{ xs: "column", md: "row" }} spacing={chartSpacing}>
				<PieChartProvider>
					<ChartStack badgeProps={pieChartBadgeProps} Chart={ClientHomepagePieChart} />
				</PieChartProvider>
				<BarChartProvider>
					<ChartStack badgeProps={barChartBadgeProps} Chart={ClientHomepageBarChart} />
				</BarChartProvider>
			</Stack>
			<TableProvider
				table={TableEnum.homepageChecks}
				tableKey={TableEnum.checks}
				pageSize={5}
			>
				<ClientHomepageTable />
			</TableProvider>
		</Stack>
	);
};

const getChartStackProps = (
	Icon: React.FC,
	information: "check_status" | "remaining_maturity",
	link: string
) => {
	const badgeProps = {
		Icon,
		localizationKeys: {
			title: `client_homepage_${information}_title_text`,
			text: `client_homepage_${information}_body_text`,
			button: `client_homepage_${information}_button_text`
		},
		link
	};
	return { badgeProps };
};
