import { Pagination, styled } from "@mui/material";
import { ColorDictionary } from "../../dictionaries/color.dictionary";

export const DashboardTablePagination = styled(Pagination)(({ theme }) => ({
	".MuiPaginationItem-root": {
		textAlign: "center",
		minWidth: "1.75rem",
		width: "1.75rem",
		height: "1.75rem",

		borderRadius: "0.5rem",

		color: theme.palette.primary.main,
		fontFamily: "Raleway",
		fontSize: "0.875rem",
		fontWeight: 500,
		lineHeight: "140%",

		"&.MuiButtonBase-root": {
			"&:hover": {
				background: ColorDictionary["#60528f1f"]
			},
			"&:active": {
				background: ColorDictionary["#60528f40"]
			},

			"&.Mui-selected": {
				background: theme.palette.primary.main,

				color: theme.palette.common.white,

				"&:hover": {
					background: theme.palette.primary.dark
				},
				"&:active": {
					background: ColorDictionary["#0d0f2b"]
				}
			}
		},

		"&.Mui-selected": {
			fontWeight: 700
		},

		"&.MuiPaginationItem-ellipsis": {
			cursor: "default"
		}
	}
}));
