import { useTheme } from "@mui/material";
import { ColorDictionary } from "../../../../util/dictionaries/color.dictionary";
import { useResponsive } from "../../../../util/hooks/useResponsive.hook";

const styles = {
	fontSize: "0.625rem",
	fontFamily: "Raleway",
	fontWeight: 400
};

export const BarChartXaxisTickLabel: React.FC<{
	text: string;
	x?: string | number;
	y?: string | number;
}> = ({ text, x, y }) => {
	const { isSmall, isMobile, isTablet } = useResponsive();
	const theme = useTheme();

	const transform = isSmall
		? "translate(-10 33) rotate(-45)"
		: isMobile
		? "translate(0 41) rotate(-43)"
		: isTablet
		? "translate(-10 34) rotate(-45)"
		: "translate(-8 38) rotate(-45)";

	return (
		<text
			x={x}
			y={y}
			textAnchor="middle"
			{...styles}
			opacity={0.65}
			fill={theme.palette.primary.main}
			transform={transform}
		>
			{text}
		</text>
	);
};

export const BarChartYaxisTickLabel: React.FC<{ text: string }> = ({ text }) => (
	<text x={-6} y={2} {...styles} fill={ColorDictionary["#6B7280"]} textAnchor="end">
		{text === "0₺" ? "" : text}
	</text>
);
