import { useMediaQuery, useTheme } from "@mui/material";

export const useResponsive = () => {
	const theme = useTheme();
	const isXLarge = useMediaQuery(theme.breakpoints.down("xxl"));
	const isLarger = useMediaQuery(theme.breakpoints.down("xl"));
	const isLarge = useMediaQuery(theme.breakpoints.down("sxl"));
	const isTablet = useMediaQuery(theme.breakpoints.down("lg"));
	const isMobile = useMediaQuery(theme.breakpoints.down("md"));
	const isSmall = useMediaQuery(theme.breakpoints.down("sm"));

	return { isTablet, isMobile, isLarger, isLarge, isXLarge, isSmall };
};
