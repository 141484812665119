import { Box, Divider } from "@mui/material";
import { ClientHomepageStack } from "../../util/theme/styled-components/layout.styled";
import { ColorDictionary } from "../../util/dictionaries/color.dictionary";
import { ClientChartInfoContent } from "./contents";
import { ChartBadgeProps } from "./types";

interface Props {
	badgeProps: ChartBadgeProps;
	Chart: React.FC;
}

export const ChartStack: React.FC<Props> = ({ badgeProps, Chart }) => (
	<ClientHomepageStack
		direction="column"
		alignItems="center"
		divider={
			<Divider
				sx={{ width: "100%", border: `1px solid ${ColorDictionary["#ececec"]}` }}
			/>
		}
		py={{ xs: "1.5rem", md: "1rem" }}
		px={{ xs: "1rem", md: "unset" }}
		spacing={{ xs: "1rem", md: "0.75rem" }}
		width={{ xs: "100%", md: "50%" }}
	>
		<Box width="100%" px={{ md: "1.5rem" }}>
			<ClientChartInfoContent {...badgeProps} />
		</Box>
		<Box width="100%">
			<Chart />
		</Box>
	</ClientHomepageStack>
);
