import { Backdrop, Box, Drawer, Stack } from "@mui/material";
import { DashboardPageItem } from "../../types";
import { DashboardSidebarItem, DashboardSidebarUserItem } from "../items";
import { DashboardSidebarCollapse, DashboardSidebarLogo } from "../images";
import { useSidebarContext } from "../../../util/contexts/sidebar.context";
import { DashboardSidebarBox } from "../../../util/theme/styled-components";
import { UserRoleEnum } from "../../../util/dictionaries/types";
import { FastEditButton, PublishButton } from "../buttons";
import { DialogKey } from "../../containers/types";
import sidebarBackground from "../../../assets/images/dashboard/dashboard-sidebar-background.svg";
import React from "react";
import { useResponsive } from "../../../util/hooks/useResponsive.hook";

interface Props {
	setActiveRoute: (route: string) => void;
	setExpanded: (expanded: boolean) => void;
	handleDialogToggle: (dialogKey: DialogKey) => void;
	isAdmin: boolean;
	activeRoute: string;
	dialogOpen: Record<DialogKey, boolean>;
	expanded: boolean;
	areClientsSynced: boolean;
	syncInProgress: boolean;
	isPublished: boolean;
	items: DashboardPageItem[];
	lastPublishDate: Date;
	userRole: UserRoleEnum;
	name: string | undefined;
}

export const DashboardSidebar: React.FC<Props> = ({
	setActiveRoute,
	setExpanded,
	handleDialogToggle,
	isAdmin,
	activeRoute,
	expanded,
	areClientsSynced,
	syncInProgress,
	items,
	isPublished,
	lastPublishDate,
	userRole,
	name
}) => {
	const { isLarge, isMobile } = useResponsive();
	const { isOpen, setIsOpen, setTransitionComplete } = useSidebarContext();
	const isTemporary = isLarge && isOpen;

	const sidebarWidthExpanded = "19.5rem";
	const sidebarWidthCollapsed = "5.5rem";
	const sidebarWidth = isOpen ? sidebarWidthExpanded : sidebarWidthCollapsed;

	const handleLogoClick = () => {
		setActiveRoute("/");
		if (isTemporary) closeSidebar();
	};

	const closeSidebar = () => setIsOpen(false);

	const transitionDuration = 500;

	const [Wrapper, wrapperProps] = isMobile
		? [Drawer, { open: isOpen, onClose: closeSidebar, transitionDuration }]
		: [Box, { sx: isLarge ? { width: sidebarWidthCollapsed, overflow: "visible" } : {} }];

	React.useEffect(() => {
		setTransitionComplete(false);
		setTimeout(() => setTransitionComplete(true), transitionDuration);
	}, [isOpen]);

	const paddingX = isOpen ? "1.5rem" : "1.375rem";

	return (
		<Wrapper {...wrapperProps}>
			{isTemporary ? (
				<Backdrop open={isTemporary} onClick={closeSidebar} invisible />
			) : null}
			<DashboardSidebarBox
				data-cy="dashboard-sidebar"
				overflows={isTemporary && !isMobile}
				sx={{
					backgroundImage: `url(${sidebarBackground})`,
					backgroundRepeat: "no-repeat",
					backgroundPosition: "0 calc(100% - 4.3rem)",
					width: sidebarWidth,
					transition: "width 0.5s ease"
				}}
			>
				<Stack
					p="1rem 1.5rem"
					direction="row"
					justifyContent={isOpen ? "space-between" : "center"}
					alignItems="center"
					height="5.75rem"
				>
					{isOpen ? <DashboardSidebarLogo handleClick={handleLogoClick} /> : null}
					<DashboardSidebarCollapse
						isSidebarOpen={isOpen}
						handleClick={() => setIsOpen(!isOpen)}
					/>
				</Stack>
				{isAdmin ? (
					<Box px={paddingX} pt="0.5rem">
						<FastEditButton />
					</Box>
				) : null}
				<Stack
					py={isAdmin ? "1.25rem" : "1.5rem"}
					pl={paddingX}
					pr={isOpen ? paddingX : "0"}
					spacing="0.5rem"
					sx={{
						overflowY: "auto",
						overflowX: "hidden"
					}}
					height="100%"
				>
					{items
						.filter(({ hidden }) => !hidden)
						.sort((a, b) => a.index - b.index)
						.map(item => (
							<DashboardSidebarItem
								item={item}
								activeRoute={activeRoute}
								setActiveRoute={setActiveRoute}
								key={item.route}
							/>
						))}
				</Stack>
				{isAdmin ? (
					<Box px={paddingX} mb="5.8rem">
						<PublishButton
							handleClick={() => handleDialogToggle("publish")}
							isCollapsed={!isOpen}
							isPublished={isPublished}
							lastPublishDate={lastPublishDate}
						/>
					</Box>
				) : null}
				<Box position="absolute" bottom={0} width="100%">
					<DashboardSidebarUserItem
						setActiveRoute={setActiveRoute}
						handleDialogToggle={handleDialogToggle}
						setExpanded={setExpanded}
						areClientsSynced={areClientsSynced}
						syncInProgress={syncInProgress}
						expanded={expanded}
						userRole={userRole}
						name={name}
					/>
				</Box>
			</DashboardSidebarBox>
		</Wrapper>
	);
};
